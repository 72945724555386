import React from 'react';

export const DashboardLogoAdmin = () => {
  return (
    <svg width="420" height="258" viewBox="0 0 420 258" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9442_138261)">
        <path
          d="M351.478 65.0185C351.478 58.4885 346.168 53.3085 339.528 53.3085C338.868 53.3085 338.198 53.3085 337.538 53.5385C335.548 47.2285 329.568 42.7285 322.498 42.7285C321.608 42.7285 320.728 42.7285 319.848 42.9585C316.968 38.6785 312.108 35.9785 306.578 35.9785C297.948 35.9785 290.868 42.9585 290.868 51.5185C290.868 52.4185 290.868 53.0985 291.088 53.9985C290.208 53.7685 289.318 53.5485 288.438 53.5485C281.798 53.5485 276.488 58.7285 276.488 65.2585C276.488 71.7885 281.798 76.9685 288.438 76.9685C288.878 76.9685 289.328 76.9685 289.548 76.7385H338.218C338.658 76.7385 339.098 76.9685 339.328 76.9685C339.768 76.9685 340.218 76.9685 340.438 76.7385H340.878C347.048 75.8285 351.478 70.8685 351.478 65.0185Z"
          fill="#B8DCFF"
        />
        <path
          d="M344.708 194.293C344.708 189.673 340.885 186.008 336.106 186.008C335.629 186.008 335.151 186.008 334.673 186.167C333.239 181.706 328.938 178.519 323.841 178.519C323.204 178.519 322.567 178.519 321.929 178.678C319.859 175.651 316.354 173.739 312.372 173.739C306.16 173.739 301.062 178.678 301.062 184.733C301.062 185.37 301.062 185.848 301.221 186.486C300.584 186.326 299.947 186.167 299.31 186.167C294.531 186.167 290.708 189.832 290.708 194.453C290.708 199.074 294.531 202.739 299.31 202.739C299.629 202.739 299.947 202.739 300.106 202.579H335.151C335.469 202.579 335.788 202.739 335.947 202.739C336.266 202.739 336.584 202.739 336.744 202.579H337.062C341.522 201.942 344.708 198.436 344.708 194.293Z"
          fill="#B8DCFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M290.288 45.3585C304.878 60.0285 303.538 83.3285 308.948 103.348C314.398 123.518 325.728 142.698 322.168 163.348C318.378 185.328 306.408 206.118 288.838 219.918C271.448 233.588 248.628 236.898 226.638 238.438C205.518 239.918 184.468 237.388 165.298 228.548C145.878 219.588 128.828 206.018 117.288 188.008C105.568 169.708 99.2082 148.258 99.8882 126.458C100.558 104.688 109.658 84.5485 121.078 65.9285C132.948 46.5685 145.498 25.0285 166.828 17.3085C187.898 9.68847 210.148 20.8885 231.918 25.8385C252.338 30.4885 275.538 30.5185 290.288 45.3585Z"
          fill="white"
        />
        <path
          d="M183.708 216.078C183.708 206.038 175.568 198.078 165.388 198.078C164.368 198.078 163.348 198.078 162.338 198.428C159.288 188.738 150.128 181.808 139.268 181.808C137.908 181.808 136.558 181.808 135.198 182.158C130.788 175.578 123.328 171.428 114.848 171.428C101.618 171.428 90.7582 182.158 90.7582 195.308C90.7582 196.688 90.7582 197.728 91.0982 199.118C89.7382 198.768 88.3882 198.428 87.0282 198.428C76.8482 198.428 68.7082 206.388 68.7082 216.428C68.7082 226.468 76.8482 234.428 87.0282 234.428C87.7082 234.428 88.3882 234.428 88.7282 234.078H163.358C164.038 234.078 164.718 234.428 165.058 234.428C165.738 234.428 166.418 234.428 166.758 234.078H167.438C176.918 232.688 183.708 225.078 183.708 216.078Z"
          fill="#B8DCFF"
        />
        <ellipse cx="165.5" cy="111" rx="70.5" ry="70" fill="#166DC2" />
        <path
          d="M154.701 48.3021C154.132 48.3358 153.55 48.3632 153.002 48.2102C152.461 48.0445 151.947 47.6479 151.822 47.0887C151.693 46.5515 151.957 45.9877 152.357 45.6099C152.758 45.2322 153.272 45.0058 153.79 44.8205C156.809 43.6965 160.122 43.381 163.29 43.9167C163.669 43.9786 164.068 44.0657 164.366 44.3239C164.741 44.6514 164.856 45.1823 164.956 45.6658C165.008 45.9122 165.05 46.1935 164.911 46.4091C164.82 46.5454 164.672 46.6376 164.53 46.7172C161.497 48.363 158.065 48.1066 154.701 48.3021Z"
          fill="#80C0FF"
        />
        <path
          d="M225.068 123.779C224.805 123.752 224.54 123.715 224.264 123.555C223.988 123.394 223.718 123.063 223.609 122.678C223.504 122.302 223.569 121.932 223.717 121.713C223.864 121.494 224.092 121.386 224.306 121.303C225.611 120.821 227.12 120.913 228.652 121.578C228.839 121.663 229.029 121.757 229.191 121.956C229.395 122.214 229.5 122.59 229.593 122.928C229.641 123.102 229.686 123.298 229.636 123.431C229.611 123.513 229.545 123.567 229.482 123.599C228.242 124.46 226.618 123.964 225.068 123.779Z"
          fill="#7AA8C9"
        />
        <g clip-path="url(#clip1_9442_138261)">
          <path
            d="M219.608 138.238C212.408 152.738 200.008 163.538 184.708 168.738C169.308 173.938 152.908 172.838 138.408 165.638C123.908 158.438 113.108 146.038 107.908 130.738C102.708 115.438 103.808 98.9384 111.008 84.4384C118.408 69.4384 131.208 58.9384 145.908 53.9384C160.608 48.9384 177.208 49.6384 192.208 57.0384C222.208 71.9384 234.508 108.338 219.608 138.238Z"
            fill="white"
          />
          <g clip-path="url(#clip2_9442_138261)">
            <path
              d="M219.608 138.338C217.608 142.338 215.308 145.938 212.608 149.338C223.908 134.238 227.808 114.038 221.408 94.8384C210.908 63.8384 177.108 47.1384 145.908 57.6384C118.208 67.0384 101.808 94.6384 105.808 122.538C103.308 109.638 105.108 96.4384 111.008 84.4384C118.408 69.4384 131.208 58.9384 146.008 53.9384C160.708 48.9384 177.308 49.6384 192.308 57.0384C222.208 71.9384 234.508 108.438 219.608 138.338Z"
              fill="#BBDBF5"
            />
          </g>
        </g>
        <path
          d="M185.191 157.02C195.432 162.694 164.999 173.593 139.979 161.338C115.273 149.23 105.373 117.911 116.028 122.749C117.973 123.634 126.449 140.626 145.546 150.088C164.642 159.551 181.778 155.131 185.191 157.02Z"
          fill="#E7E9EC"
        />
        <path d="M166.318 132.919L192.488 105.719" stroke="#E7E9EC" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M305.338 97.2286L331.508 70.0386"
          stroke="#E7E9EC"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10 10"
        />
        <path
          d="M237.708 111.739L278.708 68.7385"
          stroke="#E7E9EC"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10 10"
        />
        <path d="M218.658 132.919L192.488 105.719" stroke="#E7E9EC" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M305.338 97.2286L279.168 70.0386" stroke="#E7E9EC" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M166.318 138.219C169.245 138.219 171.618 135.846 171.618 132.919C171.618 129.991 169.245 127.619 166.318 127.619C163.391 127.619 161.018 129.991 161.018 132.919C161.018 135.846 163.391 138.219 166.318 138.219Z"
          fill="#E7E9EC"
        />
        <path
          d="M192.488 111.019C195.415 111.019 197.788 108.646 197.788 105.719C197.788 102.791 195.415 100.419 192.488 100.419C189.561 100.419 187.188 102.791 187.188 105.719C187.188 108.646 189.561 111.019 192.488 111.019Z"
          fill="#E7E9EC"
        />
        <path
          d="M331.508 75.3385C334.435 75.3385 336.808 72.9656 336.808 70.0385C336.808 67.1114 334.435 64.7385 331.508 64.7385C328.581 64.7385 326.208 67.1114 326.208 70.0385C326.208 72.9656 328.581 75.3385 331.508 75.3385Z"
          fill="#E7E9EC"
        />
        <path
          d="M218.658 138.219C221.585 138.219 223.958 135.846 223.958 132.919C223.958 129.991 221.585 127.619 218.658 127.619C215.731 127.619 213.358 129.991 213.358 132.919C213.358 135.846 215.731 138.219 218.658 138.219Z"
          fill="#E7E9EC"
        />
        <path
          d="M279.168 75.3385C282.095 75.3385 284.468 72.9656 284.468 70.0385C284.468 67.1114 282.095 64.7385 279.168 64.7385C276.241 64.7385 273.868 67.1114 273.868 70.0385C273.868 72.9656 276.241 75.3385 279.168 75.3385Z"
          fill="#E7E9EC"
        />
        <path
          d="M305.338 102.528C308.265 102.528 310.638 100.156 310.638 97.2285C310.638 94.3014 308.265 91.9285 305.338 91.9285C302.411 91.9285 300.038 94.3014 300.038 97.2285C300.038 100.156 302.411 102.528 305.338 102.528Z"
          fill="#E7E9EC"
        />
        <g clip-path="url(#clip3_9442_138261)">
          <path
            d="M169.363 104.745C169.363 117.359 158.836 127.739 146.042 127.739C133.247 127.739 122.708 117.359 122.708 104.745C122.708 92.13 133.247 81.7385 146.042 81.7385C151.859 81.7385 157.465 83.8881 161.763 87.7572L157.142 92.7564C154.214 90.1156 150.327 88.5065 146.042 88.5065C136.947 88.5065 129.572 95.778 129.572 104.745C129.572 113.699 136.947 120.971 146.042 120.971C155.123 120.971 162.498 113.699 162.498 104.745H169.363Z"
            fill="#E7E9EC"
          />
          <path
            d="M170.708 101.49H164.529C163.844 98.4434 162.536 95.6306 160.742 93.174L164.953 88.6293C168.491 91.8106 170.708 96.3922 170.708 101.49Z"
            fill="#E7E9EC"
          />
        </g>
        <path d="M165.318 129.729L191.488 102.529" stroke="#F1891A" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M304.338 94.0386L330.508 66.8486"
          stroke="#F1891A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10 10"
        />
        <path
          d="M237.708 109.739L278.708 66.7385"
          stroke="#F1891A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="10 10"
        />
        <path d="M217.658 129.729L191.488 102.529" stroke="#F1891A" strokeWidth="3" strokeMiterlimit="10" />
        <path d="M304.338 94.0386L278.168 66.8486" stroke="#F1891A" strokeWidth="3" strokeMiterlimit="10" />
        <path
          d="M165.318 135.029C168.245 135.029 170.618 132.656 170.618 129.729C170.618 126.801 168.245 124.429 165.318 124.429C162.391 124.429 160.018 126.801 160.018 129.729C160.018 132.656 162.391 135.029 165.318 135.029Z"
          fill="#E17C02"
        />
        <path
          d="M191.488 107.829C194.415 107.829 196.788 105.456 196.788 102.529C196.788 99.6015 194.415 97.2286 191.488 97.2286C188.561 97.2286 186.188 99.6015 186.188 102.529C186.188 105.456 188.561 107.829 191.488 107.829Z"
          fill="#E17C02"
        />
        <path
          d="M330.508 72.1486C333.435 72.1486 335.808 69.7757 335.808 66.8486C335.808 63.9215 333.435 61.5486 330.508 61.5486C327.581 61.5486 325.208 63.9215 325.208 66.8486C325.208 69.7757 327.581 72.1486 330.508 72.1486Z"
          fill="#E17C02"
        />
        <path
          d="M217.658 135.029C220.585 135.029 222.958 132.656 222.958 129.729C222.958 126.801 220.585 124.429 217.658 124.429C214.731 124.429 212.358 126.801 212.358 129.729C212.358 132.656 214.731 135.029 217.658 135.029Z"
          fill="#E17C02"
        />
        <path
          d="M278.168 72.1486C281.095 72.1486 283.468 69.7757 283.468 66.8486C283.468 63.9215 281.095 61.5486 278.168 61.5486C275.241 61.5486 272.868 63.9215 272.868 66.8486C272.868 69.7757 275.241 72.1486 278.168 72.1486Z"
          fill="#E17C02"
        />
        <path
          d="M304.338 99.3385C307.265 99.3385 309.638 96.9656 309.638 94.0385C309.638 91.1114 307.265 88.7385 304.338 88.7385C301.411 88.7385 299.038 91.1114 299.038 94.0385C299.038 96.9656 301.411 99.3385 304.338 99.3385Z"
          fill="#E17C02"
        />
        <g clip-path="url(#clip4_9442_138261)">
          <path
            d="M169.363 101.745C169.363 114.359 158.836 124.739 146.042 124.739C133.247 124.739 122.708 114.359 122.708 101.745C122.708 89.13 133.247 78.7385 146.042 78.7385C151.859 78.7385 157.465 80.8881 161.763 84.7572L157.142 89.7564C154.214 87.1156 150.327 85.5065 146.042 85.5065C136.947 85.5065 129.572 92.778 129.572 101.745C129.572 110.699 136.947 117.971 146.042 117.971C155.123 117.971 162.498 110.699 162.498 101.745H169.363Z"
            fill="#F1891A"
          />
          <path
            d="M170.708 98.4896H164.529C163.844 95.4434 162.536 92.6306 160.742 90.174L164.953 85.6293C168.491 88.8106 170.708 93.3922 170.708 98.4896Z"
            fill="#FFF280"
          />
        </g>
        <path
          d="M293.243 165.665L244.963 141.742C244 141.265 242.825 141.662 242.339 142.628L235.388 156.655C234.911 157.619 235.307 158.794 236.273 159.28L284.553 183.203C285.516 183.68 286.691 183.284 287.178 182.318L294.128 168.29C294.606 167.327 294.209 166.152 293.243 165.665Z"
          fill="#0D4374"
        />
        <path d="M241.869 143.568L229.333 137.357L223.313 149.507L235.848 155.718L241.869 143.568Z" fill="#BBDBF5" />
        <path d="M234.42 139.872L228.399 152.022L230.765 153.194L236.785 141.044L234.42 139.872Z" fill="#80C0FF" />
        <path
          d="M233.925 124.57L233.739 125.551C232.538 131.94 230.458 138.161 227.551 144.028C224.637 149.908 220.947 155.332 216.591 160.157L215.92 160.89C215.413 161.452 214.598 161.6 213.926 161.268L207.426 158.046C205.981 157.331 205.422 155.672 205.954 154.279C206.959 155.692 208.549 156.622 210.077 157.49C210.73 157.861 211.395 158.238 212.142 158.324C214.109 158.557 215.662 156.777 216.833 155.179C218.22 153.286 219.599 151.397 220.99 149.513C222.151 147.918 223.315 146.333 224.327 144.641C226.333 141.294 227.695 137.605 229.048 133.951C230.417 130.249 231.795 126.544 233.166 122.852C233.753 123.214 234.046 123.896 233.925 124.57Z"
          fill="#0D4374"
        />
        <path
          d="M226.507 119.537L233.008 122.758C233.68 123.091 234.046 123.832 233.913 124.563L233.726 125.544C232.526 131.933 230.445 138.155 227.541 144.031C224.634 149.898 220.944 155.322 216.579 160.151L215.911 160.894C215.404 161.455 214.598 161.6 213.926 161.267L207.426 158.046C205.614 157.148 205.194 154.747 206.589 153.284C214.881 144.608 220.335 133.619 222.211 121.756C222.53 119.76 224.695 118.639 226.507 119.537Z"
          fill="#12568A"
        />
        <path
          d="M233.925 124.57L233.739 125.551C232.538 131.94 230.458 138.161 227.551 144.028C224.637 149.908 220.947 155.332 216.591 160.157L215.92 160.89C215.413 161.452 214.598 161.6 213.926 161.268L207.426 158.046C205.981 157.331 205.422 155.672 205.954 154.279C205.892 154.437 206.723 155.181 206.846 155.297C207.183 155.606 207.5 155.889 207.887 156.128C208.73 156.625 209.507 157.239 210.368 157.697C211.411 158.253 212.69 158.571 213.813 158.055C215.092 157.465 216.018 156.298 216.826 155.192C219.602 151.406 222.634 147.724 224.871 143.592C227.032 139.612 228.601 135.347 230.088 131.088C230.379 130.262 232.625 122.592 233.154 122.878C233.753 123.214 234.046 123.896 233.925 124.57Z"
          fill="#0D4374"
        />
        <path
          d="M260.274 171.141C260.274 171.141 254.263 146.879 253.721 144.646C253.178 142.412 253.248 138.051 256.588 137.378C259.928 136.705 263.438 143.542 263.438 143.542L267.899 152.886C267.899 152.886 268.02 148.772 271.122 148.02C275.486 146.971 278.188 151.79 278.188 151.79C278.188 151.79 277.847 148.369 280.4 147.74C284.813 146.652 287.938 151.941 288.35 153.945C288.35 153.945 287.294 150.533 290.975 149.745C295.56 148.758 303.826 160.84 306.195 172.494C308.564 184.148 306.706 186.959 312.089 195.064C317.472 203.168 320.51 207.42 320.51 207.42C320.51 207.42 314.774 218.917 292.295 222.015C292.295 222.015 287.288 206.797 284.505 202.49C281.723 198.184 265.976 190.8 262.23 183.916C258.483 177.033 254.908 174.882 252.348 167.293L260.274 171.141Z"
          fill="#F29372"
        />
        <path
          d="M311.825 194.657C310.706 202.665 287.919 207.386 286.742 206.992C287.525 208.564 290.986 218.731 292.292 222.005C306.423 223.602 318.135 212.517 320.507 207.411L311.825 194.657Z"
          fill="#0D4374"
        />
        <path
          d="M289.676 150.869C289.676 150.869 288.336 151.775 288.857 153.32C289.378 154.864 291.436 159.183 291.436 159.183"
          stroke="#E67E63"
          strokeLinecap="round"
        />
        <path
          d="M280.027 148.731C280.027 148.731 278.449 149.623 278.857 151.427C279.214 153.016 282.695 160.021 282.695 160.021"
          stroke="#E67E63"
          strokeLinecap="round"
        />
        <path
          d="M269.491 149.911C269.491 149.911 268.159 151.532 268.567 153.336C268.924 154.925 271.48 160.501 271.48 160.501"
          stroke="#E67E63"
          strokeLinecap="round"
        />
        <path
          d="M263.959 185.475C269.813 191.722 280.672 197.979 282.686 199.505C286.167 202.13 287.011 206.352 287.011 206.352"
          stroke="#E67E63"
          strokeLinecap="round"
        />
        <path
          d="M254.682 139.456C254.682 139.456 253.557 141.218 254.225 144.011C254.6 145.594 261.134 173.943 261.134 173.943"
          stroke="#E67E63"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9442_138261">
          <rect width="420" height="258" fill="white" />
        </clipPath>
        <clipPath id="clip1_9442_138261">
          <rect width="121.3" height="121.2" fill="white" transform="translate(104.708 50.7384)" />
        </clipPath>
        <clipPath id="clip2_9442_138261">
          <rect width="121.2" height="98.6" fill="white" transform="translate(104.708 50.7384)" />
        </clipPath>
        <clipPath id="clip3_9442_138261">
          <rect width="48" height="46" fill="white" transform="translate(122.708 81.7385)" />
        </clipPath>
        <clipPath id="clip4_9442_138261">
          <rect width="48" height="46" fill="white" transform="translate(122.708 78.7385)" />
        </clipPath>
      </defs>
    </svg>
  );
};
