import React, { useContext } from 'react';
import { SELLER, ADMIN } from 'variables/roles';
import SessionContext from 'api/session/context';
import { Reports } from './reports';
import { AccessHall } from './access-hall';
import { DashboardAdmin } from 'components/DashboardAdmin/dashboard-admin';
import Reputacion from './reputation';
import Metrics from '../eiffel/metrics';

export function Home() {
  const {
    auth: { role, sellerIsInPublishHistory },
  } = useContext(SessionContext);

  return (
    <>
      {![SELLER].includes(role) || sellerIsInPublishHistory ? (
        <>
          {[SELLER].includes(role) && (
            <>
              <Reputacion />
              <Metrics />
              <Reports />
            </>
          )}
          {[ADMIN].includes(role) && (
            <>
              <DashboardAdmin />
            </>
          )}
        </>
      ) : (
        <AccessHall />
      )}
    </>
  );
}
