import { matchPath } from 'react-router-dom';

import TagManager from 'react-gtm-module';

export const trackGTMPageView = (props: { path: any; routes: any }) => {
  const { path, routes } = props;

  const route = routes.find((item: any) =>
    matchPath(path, {
      path: item.path,
      exact: true,
      strict: true,
    }),
  );

  if (route) {
    const tagManagerArgs = {
      dataLayer: {
        event: 'PageviewVirtual',
        page: route.path,
        title: route.text,
        location: window.location.href,
      },
    };

    TagManager.dataLayer(tagManagerArgs);
    return route;
  }

  return;
};

export const trackGTMEventAction = (props: { category: string; action: string; tag: string }, idSeller?: string) => {
  const { category, action, tag } = props;

  const tagManagerArgs = {
    dataLayer: {
      category,
      accion: action,
      tag,
      idSeller: idSeller || '0',
      event: 'event-interactive',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const trackGTMEventActionIdentifyUser = (action, tag, idSeller, userType) => {
  const tagManagerArgs = {
    dataLayer: {
      category: 'Identificación de Usuario',
      accion: action,
      tag,
      idSeller: idSeller || '0',
      tipoUsuario: userType || '',
      event: 'event-interactive',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
export const trackGTMPageViewCuston = (dataLayer: { page: string; title: string }) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'PageviewVirtual',
      page: dataLayer.page,
      title: dataLayer.title,
      location: window.location.href,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const trackGTMEventActionCuston = (props: { category: string; action: string; tag: string }) => {
  const { category, action, tag } = props;

  const tagManagerArgs = {
    dataLayer: {
      category,
      accion: action,
      tag,
      event: 'event-interactive',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const trackGTMEventActionTourGuide = (props: {
  category: string;
  action: string;
  tag: string;
  tourGuide: string;
}) => {
  const { category, action, tag, tourGuide } = props;

  const tagManagerArgs = {
    dataLayer: {
      category,
      accion: action,
      tag,
      event: 'event-interactive',
      tourGuide,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};
