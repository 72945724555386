import React from 'react';
import t from '../../utils/translate';
import { DashboardLogoAdmin } from 'components/icons/dashboardLogo-admin';
import { Typography } from '@cencosud-ds/bigbang';
import './dashboard-admin.css';

export const DashboardAdmin = () => {
  return (
    <div className="contenedor">
      <div className="contenedor-logo">
        <DashboardLogoAdmin />
      </div>
      <div className="contenedor-textos">
        <Typography
          typeElement="h2"
          weight="semiBold"
          color="#166DC2"
          children="Consulta las métricas en el módulo de Reportes"
        />

        <Typography typeElement="subtitle2" weight="regular" color="#334661" children={t('msgAdminDashboard')} />
      </div>
    </div>
  );
};
