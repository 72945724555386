import React, { useEffect, useContext, useState } from 'react';
import { Loader } from '@cencosud-ds/bigbang';
import { SELLER } from 'variables/roles';
import SessionContext from 'api/session/context';
import { getDashboard } from 'api/report/index';

import './style.scss';

export function Reports() {
  const [iframeUrl, setIframeUrl] = useState('');
  const [showLoading, setShowLoading] = useState(true);

  const {
    auth: { jwt, role },
  } = useContext(SessionContext);

  const loadToFalse = () => {
    setShowLoading(false);
  };

  const fetchReportHome = async () => {
    try {
      const url = await getDashboard(jwt, 'home');
      setIframeUrl(url);
    } catch (error) {
      console.log('Ocurrio un error al cargar reporte en Home', error);
    }
  };

  useEffect(() => {
    if ([SELLER].includes(role)) {
      fetchReportHome();
    }
  }, []);

  return (
    <div className="container-report">
      <>
        {showLoading && <Loader />}
        <iframe onLoad={loadToFalse} title="Report 360" frameBorder="0" height="505px" width="100%" src={iframeUrl} />
      </>
    </div>
  );
}
