import * as roles from '../variables/roles';
import * as sellerTypes from '../variables/sellerTypes';
import { uniq } from 'lodash';
import objectHasProperty from './objectHasProperty';

export const logoutRoute = {
  path: '/logout',
  pathComponent: 'logout',
  text: 'Cerrar sesión',
  icon: 'Cerrarsesion',
  hidden: true,
  variant: 'secondary',
  exact: true,
};

export const homeRoute = {
  id: 'Home',
  path: '/eiffel',
  text: 'Inicio',
  icon: 'Resumen',
  exact: true,
};

export const helpCenterRoute = {
  id: 'helpCenterRoute',
  path: '', //https://ayuda.marketplace.paris.cl
  text: 'Ayuda',
  icon: 'Ayuda',
  isParentWithoutPath: true,
  parentId: '',
  componentPath: '',
  exact: true,
  subRoutes: [
    {
      id: 'tourGuideRoute',
      exact: true,
      parentId: 'helpCenterRoute',
      path: '/helper/tourguide',
      text: 'Guía de uso Market Place',
      icon: 'Ordenes',
      componentPath: 'helper/tourguide',
      fixedAction: 'tourGuideAction',
    },
    {
      id: 'tourGuideRoute',
      exact: true,
      parentId: 'helpCenterRoute',
      path: 'https://ayuda.marketplace.paris.cl',
      text: 'Centro de Ayuda',
      icon: 'HelpCenter',
      blank: true,
      fixedAction: 'helpCenter',
    },
  ],
};

export const myAccountRoute = {
  id: 'MyAccount',
  path: '/user/myAccount',
  text: 'Mi cuenta',
  pathComponent: 'Account',
  icon: 'Micuenta',
  exact: true,
};

export const configRoute = {
  id: 'Config',
  path: '',
  text: 'Configuracion',
  icon: 'Configuracion',
  isParentWithoutPath: true,
  parentId: '',
  componentPath: '',
  exact: true,
};

export const spid35Route = {
  id: 'Spid35',
  path: '',
  text: 'Spid35',
  icon: 'Productos',
  isParentWithoutPath: true,
  parentId: '',
  componentPath: '',
  exact: true,
};

export const salesRoute = {
  id: 'Sales',
  path: '',
  text: 'Ventas',
  icon: 'Ventas',
  isParentWithoutPath: true,
  parentId: '',
  componentPath: '',
  exact: true,
};

export const financeRoute = {
  id: 'Finance',
  path: '',
  text: 'Finanzas',
  icon: 'Finance',
  isParentWithoutPath: true,
  parentId: '',
  componentPath: '',
  exact: true,
};

export const commonRoutes = [homeRoute, myAccountRoute, logoutRoute];
export const headerRoutes = [myAccountRoute, logoutRoute];

export const loginRoute = { path: '/login', text: 'Login' };

export const getRoutes = (routes, role, permissions, sellerType, isCollector) => {
  if (!routes) {
    return [];
  }

  let finalRoutes = [];

  if (role === 'admin') {
    finalRoutes = [...routes];
  } else {
    for (const microfrontend in permissions) {
      finalRoutes = finalRoutes.concat(
        getRoutesFromMicrofrontend(routes, role, permissions[microfrontend], sellerType, isCollector),
      );
    }
  }

  const hasSalesRoute = finalRoutes.find(r => r.parentId === 'Sales');
  if (hasSalesRoute) {
    finalRoutes.unshift(salesRoute);
  }

  const hasConfigRoute = finalRoutes.find(r => r.parentId === 'Config');
  if (hasConfigRoute) {
    finalRoutes.unshift(configRoute);
  }

  const hasProductsRoute = finalRoutes.find(r => r.parentId === 'Spid35');
  if (hasProductsRoute) {
    finalRoutes.unshift(spid35Route);
  }

  const hasFinanceRoute = finalRoutes.find(r => r.parentId === 'Finance');
  if (hasFinanceRoute) {
    finalRoutes.unshift(financeRoute);
  }

  finalRoutes.unshift(homeRoute);
  finalRoutes.unshift(logoutRoute);

  return orderRoutes(uniq(finalRoutes));
};

const getRoutesFromMicrofrontend = (routes, role, permissions, sellerType, isCollector) => {
  switch (role) {
    case roles.SYSADMIN:
      if (permissions.sysAdminRoutes) {
        const enabledRoutes = routes.filter(r => r.isParentWithoutPath || permissions.sysAdminRoutes.includes(r.path));
        return enabledRoutes;
      }
      return [];
    case roles.ADMIN:
      return routes;
    case roles.SELLER:
      if ([sellerTypes.CENTRY, sellerTypes.MULTIVENDE].includes(sellerType)) {
        return routes.filter(r => r.isParentWithoutPath || permissions.externalSellerRoutes.includes(r.path));
      }
      return routes.filter(r => {
        if (!isCollector) {
          return r.isParentWithoutPath || (permissions.sellerRoutes.includes(r.path) && r.path !== '/eiffel/finance');
        }
        return r.isParentWithoutPath || permissions.sellerRoutes.includes(r.path);
      });
    case roles.CATALOG:
      return routes.filter(r => r.isParentWithoutPath || permissions.catalogRoutes.includes(r.path));
    case roles.COMERCIAL:
      return routes.filter(r => r.isParentWithoutPath || permissions.comercialRoutes.includes(r.path));
    case roles.LOGISTICS:
      return routes.filter(r => r.isParentWithoutPath || permissions.logisticsRoutes.includes(r.path));
    case roles.CUSTOMER_SERVICE:
      return routes.filter(r => r.isParentWithoutPath || permissions.customerServiceRoutes.includes(r.path));
    case roles.STORE:
      return routes.filter(r => r.isParentWithoutPath || permissions.storeRoutes.includes(r.path));
    default:
      return [];
  }
};

const orderRoutes = routesForMenu => {
  const menuPriorities = {
    Home: 1,
    CencoPim: 2,
    CatalogApproval: 3,
    Sales: 4,
    Shipments: 5,
    Stock: 6,
    Epv: 7,
    Finance: 8,
    Account: 9,
    Existences: 10,
    Metrics: 11,
    Campaigns: 12,
    Reports: 13,
    default: 999,
  };

  return routesForMenu.sort((route1, route2) => {
    const priorityFromRoute1 = objectHasProperty(menuPriorities, route1.id)
      ? menuPriorities[route1.id]
      : menuPriorities.default;
    const priorityFromRoute2 = objectHasProperty(menuPriorities, route2.id)
      ? menuPriorities[route2.id]
      : menuPriorities.default;

    if (priorityFromRoute1 > priorityFromRoute2) {
      return 1;
    }

    if (priorityFromRoute1 < priorityFromRoute2) {
      return -1;
    }

    return 0;
  });
};
