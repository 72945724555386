import React from 'react';
import t from '../../../../../../utils/translate';
import {
  CardContainer,
  CardContainerBody,
  CardContainerBodyData,
  CardContainerBodyDateRange,
  CardContainerBodyEmpty,
  CardContainerHeader,
  CardIconAndTitleContainer,
  CardIconContainer,
  CardTitleContainer,
  IconInnerContainer,
} from './style';
import { Typography, Loader } from '@cencosud-ds/bigbang';
import CardTooltip from '../tooltip';

const Card: React.FC<{
  showData: boolean;
  title: string;
  data: string;
  currency: string;
  isPercentage: boolean;
  tooltipMessage: string;
  icon: JSX.Element;
  isLoading: boolean;
  onClick: () => void;
}> = props => {
  const { showData, currency, isPercentage, data, title, tooltipMessage, icon, isLoading, onClick } = props;
  return (
    <CardContainer>
      <CardContainerHeader>
        <CardIconAndTitleContainer>
          <CardIconContainer>
            <IconInnerContainer>{icon}</IconInnerContainer>
          </CardIconContainer>
          <CardTitleContainer>
            <Typography color={'#A4A7AC'} propsElement={{}} typeElement="body2" weight="semiBold">
              {title}
            </Typography>
          </CardTitleContainer>
        </CardIconAndTitleContainer>
        <CardTooltip text={tooltipMessage} />
      </CardContainerHeader>
      {showData ? (
        <CardContainerBody onClick={onClick}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <CardContainerBodyData>
                <Typography color={'#166DC2'} propsElement={{}} typeElement="h2" weight="bold">
                  {`${currency}${data}${isPercentage ? '%' : ''}`}
                </Typography>
              </CardContainerBodyData>
              <CardContainerBodyDateRange>
                <Typography color={'#777'} propsElement={{}} typeElement="body2" weight="semiBold">
                  {t('metrics_home_card_body_label')}
                </Typography>
              </CardContainerBodyDateRange>
            </>
          )}
        </CardContainerBody>
      ) : (
        <CardContainerBodyEmpty>
          <Typography color={'#A4A7AC'} propsElement={{}} typeElement="body2" weight="semiBold">
            {t('metrics_without_data_text')}
          </Typography>
        </CardContainerBodyEmpty>
      )}
    </CardContainer>
  );
};

export default Card;
