import React, { useContext, useEffect } from 'react';
import t from '../../../../utils/translate';
import { ButtonContainer, MetricsContainer, MetricsContainerHeader, TitlesContainer, CardsContainer } from './style';
import { Typography, colors, Button } from '@cencosud-ds/bigbang';
import { Barchart, Person, Cart } from './components/icons';
import Card from './components/card';
import { useHistory } from 'react-router-dom';
import { getSellerReportConversion, getSellerReportSales, getSellerReportViews } from 'api/metrics';
import SessionContext from 'api/session/context';
import { formartNumberPoint } from 'utils/patterns';
import { ReportViews, SalesInfo, SellerConvertionResponse } from 'api/metrics/interface';
import { useApi } from './hooks/useApi';
import { trackGTMEventAction } from 'utils/analytics';

const Metrics: React.FC<{}> = () => {
  const history = useHistory();

  const {
    auth: { jwt, sellerId },
  } = useContext(SessionContext);

  const { data: convertion, isLoading: sellerConvertionLoading, execute: execConvertion } = useApi<
    SellerConvertionResponse,
    { jwt: string; sellerId: string }
  >(getSellerReportConversion);
  const { data: reportSalesResponse = [], isLoading: reportSalesLoading, execute: execReportsViews } = useApi<
    ReportViews[],
    { jwt: string; sellerId: string }
  >(getSellerReportViews);
  const { data: average, isLoading: averageLoading, execute: execAverage } = useApi<SalesInfo, { jwt: string }>(
    getSellerReportSales,
  );

  useEffect(() => {
    execConvertion({ jwt, sellerId });
    execReportsViews({ jwt, sellerId });
    execAverage({ jwt });
  }, []);

  const onClick = () => {
    return history.push(`/reports/metrics`);
  };

  return (
    <MetricsContainer>
      <MetricsContainerHeader>
        <TitlesContainer>
          <Typography color={colors.primary[500]} propsElement={{}} typeElement="subtitle1" weight="bold">
            {t('metrics_home_title')}
          </Typography>
          <Typography color={'#777777'} propsElement={{}} typeElement="body2" weight="semiBold">
            {t('metrics_home_subtitle')}
          </Typography>
        </TitlesContainer>
        <ButtonContainer>
          <Button type="button" type-button="secondary" variant-button="s" onClick={onClick}>
            {t('metrics_home_button_title')}
          </Button>
        </ButtonContainer>
      </MetricsContainerHeader>
      <CardsContainer>
        <Card
          showData={true}
          title={t('metrics_home_card1_title')}
          tooltipMessage={t('metrics_home_card1_tooltip')}
          currency={''}
          isPercentage={true}
          data={convertion ? convertion.convertion.value[0].result : ''}
          icon={<Barchart />}
          isLoading={sellerConvertionLoading}
          onClick={onClick}
        />
        <Card
          showData={true}
          title={t('metrics_home_card2_title')}
          tooltipMessage={t('metrics_home_card2_tooltip')}
          currency={''}
          isPercentage={false}
          data={
            reportSalesResponse.length
              ? reportSalesResponse
                  .reduce((a, o) => {
                    return a + Number(o.pdp_views);
                  }, 0)
                  .toString()
              : ''
          }
          icon={<Person />}
          isLoading={reportSalesLoading}
          onClick={onClick}
        />
        <Card
          showData={true}
          title={t('metrics_home_card3_title')}
          tooltipMessage={t('metrics_home_card3_tooltip')}
          currency={'$'}
          isPercentage={false}
          data={average ? formartNumberPoint(average.netSales) : ''}
          icon={<Cart />}
          isLoading={averageLoading}
          onClick={onClick}
        />
      </CardsContainer>
    </MetricsContainer>
  );
};

export default Metrics;
