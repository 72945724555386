import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SessionContext from '../../api/session/context';
import t from '../../utils/translate';
import { Input, Typography, Button, Notification } from '@cencosud-ds/bigbang';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { trackGTMEventAction } from '../../utils/analytics';
import { getServicesStatus } from '../../single-spa/getServicesStatus';
import { LoaderStyled } from 'views/layouts/styles';
import { ServiceUnavailableCurtain } from './serviceUnavailableCurtain';

const Login = () => {
  const history = useHistory();
  const { auth, login, flashMessage } = useContext(SessionContext);
  const { message } = flashMessage;
  const [notificationClose, setNotificationClose] = useState(message !== '');
  const [hasAllServicesUnavailable, setHasAllServicesUnavailable] = useState<boolean>(false);
  const [loadingServicesStatus, setLoadingServicesStatus] = useState<boolean>(false);
  const [inputsFocused, setInputFocused] = useState(false);

  useEffect(() => {
    setLoadingServicesStatus(true);

    getServicesStatus()
      .then(res => setHasAllServicesUnavailable(res.hasAllServicesUnavailable))
      .catch(error => console.warn('Fetch Services Status Error: ', error))
      .finally(() => setLoadingServicesStatus(false));
  }, []);

  useEffect(() => {
    const email = document.getElementsByName('email')[0];
    if (email) {
      setTimeout(() => {
        if (email.matches(':-internal-autofill-selected')) {
          setInputFocused(true);
        }
      }, 150);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('Must be a valid email'))
        .required(t('Required field')),
      password: Yup.string().required(t('Required field')),
    }),
    onSubmit: login,
  });

  const btnDisabled = (): boolean => {
    let retVal = true;

    if (inputsFocused) {
      retVal = false;
    }
    if (formik.dirty === true && formik.isValid === true) {
      retVal = false;
    } else {
      retVal = true;
    }

    return retVal;
  };

  return (
    <div className="App-login">
      <Helmet>
        <title>{t('Marketplace Paris')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>
      <div className="mr-2" style={{ position: 'absolute', right: 0, marginTop: '1em' }}>
        {message && notificationClose && (
          <div className="row justify-content-sm-end mb-1 mr-3">
            <div className="text-end mh-80" style={{ textAlign: 'start' }}>
              <Notification
                extended={false}
                onClose={() => {
                  setNotificationClose(false);
                }}
                variant="success"
              >
                <Typography color="#4F5C70" propsElement={{}} typeElement="body3" weight="400">
                  {t('cuentaVinculada')}
                </Typography>
              </Notification>
            </div>
          </div>
        )}
      </div>
      {loadingServicesStatus ? (
        <LoaderStyled size="large" />
      ) : (
        <>
          {hasAllServicesUnavailable ? (
            <ServiceUnavailableCurtain type="all" isCurrentServiceUnavailable={true} />
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                height: '80vh',
              }}
            >
              <div className="container ">
                <>
                  <div className="row justify-content-md-center mb-5">
                    <div
                      className="col-md-7 col-sm-12 col-xs-12"
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <Typography color="#166DC2" propsElement={{}} typeElement="h3" weight="bold">
                        {t('estasDeVuelta')}
                      </Typography>
                      <Typography color="#394C67" propsElement={{}} typeElement="body1" weight="regular">
                        {t('ingresaDatos')}
                      </Typography>
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-5">
                    <div className="col-md-6 col-sm-12 text-center">
                      <Input
                        name="email"
                        feedback-text={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                        icon-end={null}
                        input-state={formik.touched.email && formik.errors.email ? 'error' : 'default'}
                        label-text={t('correoElectronico')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        width="100%"
                        placeholder=""
                        input-focused={inputsFocused}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-4">
                    <div className="col-md-6 col-sm-12 text-center">
                      <Input
                        name="password"
                        feedback-text={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                        icon-end={null}
                        input-state={formik.touched.password && formik.errors.password ? 'error' : 'default'}
                        label-text={t('Password')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        width="100%"
                        type="password"
                        placeholder=""
                        input-focused={inputsFocused}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-4">
                    <div className="col-md-6 col-sm-12 " style={{ display: 'flex', justifyContent: 'end' }}>
                      <Typography
                        color="#166DC2"
                        propsElement={{}}
                        typeElement="body1"
                        weight="semiBold"
                        onClick={() => {
                          trackGTMEventAction({
                            category: 'Login',
                            action: 'Clic olvidé mi contraseña',
                            tag: '/login',
                          });
                          history.push('/forgot/password');
                        }}
                      >
                        <span style={{ cursor: 'pointer' }}>{t('olvidasteConrasena')}</span>
                      </Typography>
                    </div>
                  </div>
                  <div className="row justify-content-md-center mb-5">
                    <div
                      className="col-md-6 col-sm-12 text-center"
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                      <Button
                        type="submit"
                        disabled={btnDisabled()}
                        type-button="primary"
                        variant-button="m"
                        width="100%"
                        loading={auth.loading}
                      >
                        {t('ingresar')}
                      </Button>
                      <br />
                      <Typography color="#E54717" propsElement={{}} typeElement="body2" weight="semiBold">
                        {t(auth.loginError)}
                      </Typography>
                    </div>
                  </div>
                </>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default Login;
